@import url(https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}


.banner-btns {
    margin: 1rem 0 2rem 0;
}
:root{
    --filter: brightness(0) saturate(100%) invert(49%) sepia(32%) saturate(2105%) hue-rotate(356deg) brightness(104%) contrast(81%);
    --primary-color: linear-gradient(to right, #d22a43 0, #f0760a 100%);
    --font-color:#e26f20;
    --secondry-color: #221a42;
}
body {
  overflow-x: hidden;
  background: #fdfdfd;
  font-family: Cabin, sans-serif;
}

main {
  overflow: hidden;
}

a {
  text-decoration: none;
}

.btn:focus {
  outline: none;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

input:focus {
  outline: none;
  box-shadow: none;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #e3731e;
  border: 1px solid #fff;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.mobile_menu {
    background: black;
    position: fixed;
    bottom: 0;
    z-index: 10000;
    width: 100%;
    display: none;
}
.mobile_menu ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}
.mobile_menu ul li {
       background: linear-gradient(to right,rgb(187 23 47) 0,#e77c1d 100%);
    padding: 12px;
    border-radius: 1px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 1px;
}
.mobile_menu ul li a {
    font-size: 23px;
    color: #fff;
}
.mobile_menu ul li a i{}
.header_sticky.sticky{
    background-color: #241740;
    box-shadow: 0 0px 10px 0px rgb(0 0 0 / 20%);
    transition: all 0.3s ease-in;
    position: fixed;
    width: 90%;
    border-radius: 10px;
    padding: 10px 0;
    z-index: 999;
}

.header {
  padding: 7px 5rem;
  position: absolute;
  width: 100%;
  background: rgb(41 39 46 / 25%);
  box-shadow: 0 8px 32px 0 rgb(190 190 190 / 21%);
}

.header header nav.navigation li {
  font-size: 1.9rem;
  padding-right: 26px;
}

.header header nav.navigation li .dropdown-menu li {
  padding: 0;
}

.header header .navigation li a {
  font-weight: 600;
  transition: .3s ease-in;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
}

.header header .header-btn {
  font-size: 16px;
  background: #221a42;
  padding: 9px 28px;
  border-radius: 100px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  border: none;
}
.header_sticky.sticky .header-btn {
  transition: all 0.3s ease-in-out;
  background: var(--primary-color);
}

.header header .logo {
  width: 211px;
}

.header header .navigation li a:hover {
  color: var(--font-color);
}

.header header .navigation .dropdown-toggle {
  background-color: transparent;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  transition: .3s ease-in;
  font-size: 18px;
}

.header header .dropdown-menu.show,
.offcanvas {
  background: var(--primary-color);
}

.header header .dropdown-menu.show,
.offcanvas li {}

.header header .dropdown-menu.show,
.offcanvas li a {}

.header header .dropdown-menu.show {
  border-radius: 10px;
  border: 0;
}

.header .offcanvas .offcanvas-manu.nav ul li {
  font-size: 3.4rem;
  padding-top: 20px;
}

.header .offcanvas .learn-more,
.learn-more:hover,
.offcanvas-manu.nav ul li a,
.service-box:hover,
.text {
  color: #fff;
}

.header .offcanvas .white-logo {
  width: 200px;
}

.header .offcanvas .close-btn-offcanvas {
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
}

.header .offcanvas .offcanvas-header .btn-close {
  font-size: 12px;
}

.header .offcanvas .accordion-button {
  background: transparent !important;
  color: #fff;
  font-size: 56px;
  padding: 0;
}

.header .offcanvas .accordion-item {
  background: transparent !important;
  border: none;
}

.header .offcanvas .accordion-button:focus {
  box-shadow: none;
}

.header .offcanvas .accordion-button::after {
  filter: invert(1);
}


footer {
  background: url(./assets/images/footer-bg.png) 0 0/cover no-repeat;
  color: #fff;
  padding: 7rem 0 1rem;
}

footer .footer-logo {
  margin-bottom: 30px;
  width: 275px;
}

footer .form-sec p,
.overlay h4,
footer .sec-para {
  color: #fff;
  font-size: 16px;
}

footer .footer-navigation h3,
footer .learn-more-btn2,
footer .packages-box-head span {
  color: #fff;
  font-weight: 700;
}

footer .footer-navigation ul {
  padding-left: 0;
}

footer .footer-navigation li {
  padding-top: 17px;
}

footer .footer-navigation li a {
  font-weight: 400;
  color: #fff;
}

footer .footer-navigation li a i {
  padding: 0 2px;
  font-size: 12px;
  color: #f68133;
}

footer .policy-links a {
  font-size: 17px;
  color: #fff;
  margin: 0 0 0 22px;
}

footer .footer-bottom {
  border-top: 2px solid #e4751e;
  margin-top: 45px;
}


.scroll-to-top {
  width: 50px;
  height: 50px;
  background: var(--primary-color);
  position: fixed;
  bottom: 88px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: .4s;
  transition: .4s;
  display: none;
  border-radius: 50%;
}

.scroll-to-top i {
  color: #fff;
  font-size: 18px;
  line-height: 50px;
  -webkit-transition: .4s;
  transition: .4s;
}

.theme-btn.bt-support-now {
  background: #1ebbf0;
  background: -moz-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
  background: linear-gradient(to right, rgb(187 23 47) 0, #e77c1d 100%);
  bottom: 146px;
}
.theme-btn:hover{
  color: #fff;
}

.theme-btn.bt-buy-now {
  background: #8ac346;
  background: -moz-linear-gradient(top, #a3d179 0, #88ba46 100%);
}

.theme-btn {
  border-radius: 40px;
  bottom: 59px;
  display: table;
  height: 66px;
  left: 30px;
  min-width: 66px;
  text-align: center;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px;
}

.theme-btn,
span.coupon {
  position: fixed;
  color: #fff;
}

.theme-btn,
span.coupon {
  animation: .5s infinite alternate scrollDownAnimation;
}

@keyframes scrollDownAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.floatbutton .clickbutton .crossplus:after,
.floatbutton .clickbutton .crossplus:before {
  content: "";
  display: none;
  z-index: 99;
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0
}

.floatbutton .ban-form input,
.floatbutton .ban-form textarea {
  padding: 10px 15px;
  outline: 0 !important
}

.floatbutton .theme-btn,
span.coupon {
  animation: .5s infinite alternate scrollDownAnimation
}

.floatbutton .banner-form h3,
.floatbutton span.coupon {
  text-transform: uppercase
}
.floatbutton:hover.active {
  right: 0;

}
.floatbutton.active {
  right: 0;
  z-index: 999;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: .4s
}
.floatbutton .clickbutton .crossplus {
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s
}

.floatbutton {
  transition: .4s;
  position: fixed;
  right: -370px;
  top: 16%;
  font-size: 0;
  width: 420px;
  z-index: 999
}

.floatbutton  .banner-form,
.floatbutton .clickbutton {
  position: relative;
  display: inline-block
}

.floatbutton .clickbutton {
  border-radius: 30px 0 0 30px;
  background: #134e5e;
  width: 50px;
  z-index: 999;
  height: 300px;
  cursor: pointer;
  box-shadow: -20px 7px 18px -7px rgba(87, 184, 151, .09);
  padding-top: 0;
  vertical-align: top;
  background: -webkit-linear-gradient(218deg, #fdc830 0, #f37335 60%, #f37335 100%);
  background: --primary-bg;
  margin-top: 198px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  -ms-box-shadow: 0 0 40px #00000026;
  -o-box-shadow: 0 0 40px #00000026;
  border: 3px solid #fff
}

.floatbutton .clickbutton .crossplus:before {
  width: 20px;
  height: 2px;
  background: #fbb334
}

.floatbutton .clickbutton .crossplus:after {
  width: 2px;
  height: 20px;
  background: #fab334
}

.floatbutton .clickbutton .crossplus {
  display: block;
  transition: .4s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
  font-size: 16px;
  color: #fff;
  left: 0;
  right: 0;
  top: 0;
  white-space: pre;
  bottom: 0;
  font-weight: 700;
  z-index: 100
}

.floatbutton .clickbutton .crossplus.rotate {
  -webkit-transition: .4s;
  -moz-transition: .4s;
  -o-transition: .4s;
  transition: .4s
}

.floatbutton .clickbutton .crossplus i {
  font-size: 18px;
  color: #f43535;
  margin: 17px 0 0 15px
}

.floatbutton .banner-form {
  background: #fff;
  padding: 30px;
  z-index: 9;
  border-radius: 5px 0 0 5px;
  margin: 0;
  width: 370px;
  box-shadow: 0 0 30px #0000001f
}



.floatbutton .ban-form input {
  width: 100%;
  margin: 0 0 15px;
  border: 1px solid #e5e5e5;
  background: #fff;
  color: gray;
  font-size: 14px;
  border-radius: 3px;
  height: 50px;
  font-weight: 400;
  font-family: sans-serif
}

 .floatbutton .banner-form .intl-tel-input {
  width: 100%
}

 .floatbutton .banner-form h3 {
  color: #221a42;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500
}

 .floatbutton .ban-form input[type=submit] {
  color: #fff;
  font-weight: 600;
  border-radius: 50px;
  text-align: center;
  background: linear-gradient(218deg, #fdc830 0, #f37335 60%, #f37335 100%);
  padding: 10px 15px;
  margin: 20px 0 0;
  border: transparent;
  font-family: sans-serif;
  text-transform: capitalize
}

 .floatbutton .ban-form textarea {
  width: 100%;
  border: 1px solid #e5e5e5;
  background: #fff;
  color: gray;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 400;
  height: 120px;
  margin: 1px 0 0
}

.floatbutton .btns_wrap .call_wrap,
.floatbutton .btns_wrap .chat_wrap {
  position: absolute;
  padding: 0;
  border-radius: 50px 0 0 50px;
  overflow: hidden;
  box-shadow: 0 0 40px #00000026 !important;
  background: #fff
}

.floatbutton .btns_wrap {
  position: fixed;
  right: -6px
}

.floatbutton .btns_wrap a:hover {
  text-decoration: none !important;
  right: 0
}

.floatbutton .btns_wrap .call_wrap {
  width: 295px;
  right: -240px;
  top: 120px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.floatbutton .btns_wrap .call_wrap span {
  color: #333;
  font-size: 20px;
  vertical-align: middle;
  background: #fff;
  padding: 15px 20px 15px 15px
}

.floatbutton .btns_wrap .chat_wrap {
  display: block;
  right: -225px;
  width: 280px;
  top: 40px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}



.floatbutton .btns_wrap .call_wrap span.icoo,
.floatbutton .btns_wrap .chat_wrap span.icoo {
  color: #fff;
  background: #134e5e;
  background: -webkit-linear-gradient(218deg, #fdc830 0, #f37335 60%, #f37335 100%);
  background: --primary-bg;
  font-size: 18px;
  padding: 18px 20px 16px;
  border: 3px solid #ded9d9;
  vertical-align: middle;
  display: inline-block;
  border-radius: 50px 0 0 50px;
  box-shadow: 1px 0 13px #f49617
}

.floatbutton .btns_wrap .chat_wrap span {
  color: #333;
  font-size: 20px;
  vertical-align: middle;
  background: #fff;
  padding: 15px 30px 15px 15px
}


.banner-wrap {
    background-image: url(./assets/images/banner-bg.jpg);
    background-size: cover;
    background-position: center center;
    padding: 14rem 7rem 9rem;
}
.banner-wrap .service-banner-content {
    width: 75%;
}
.banner-wrap .banner-content h1 {
    color: #212529;
    font-size: 51px;
    margin: 0 0 29px;
    line-height: 58px;
    text-transform: capitalize;
}

.banner-wrap .banner-content h1 span {
    color: var(--font-color);
}
.banner-wrap .banner-content ul.service-banner-list {
    margin: 0 0 20px;
    padding: 0;
}
.banner-wrap .banner-content ul.service-banner-list li {
    margin: 0 0 5px;
    display: flex;
    align-items: center;
}
.banner-wrap .banner-content ul.service-banner-list li i {
    color: var(--font-color);
    margin: 0 10px 0 0;
}
.banner-wrap .banner-content ul.service-banner-list li p {
    color: #212529;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}
.banner-wrap .banner-content .service-banner-btns {
    display: flex;
    align-items: center;
    margin: 30px 0 20px;
}

.banner-wrap .banner-btns button.popup-btn {
    color: #fff;
    font-size: 18px;
    box-shadow: none;
    text-align: center;
    border: none;
    width: 175px;
    padding: 10px 20px;
    border-radius: 50px;
    margin: 0 10px 0 0;
    transition: .3s ease-in-out;
}

.banner-wrap .banner-btns button.popup-btn, 
.banner-wrap .banner-form .form-group button.submit-btn {
    background: linear-gradient(to right,rgb(187 23 47) 0,#e77c1d 100%);
    text-transform: capitalize;
}
.banner-wrap .banner-btns a.chat-btn {
    background: #221a42;
    color: #fff;
    font-size: 18px;
    box-shadow: none;
    text-align: center;
    border: none;
    padding: 10px 20px;
    width: 175px;
    text-transform: capitalize;
    border-radius: 50px;
    margin: 0 10px 0 0;
    transition: .3s ease-in-out;
}
.banner-wrap .banner-content p {
    color: #212529;
    font-size: 21px;
    font-weight: 500;
    width: 75%;
}

.banner-wrap .banner-content ul {
    margin: 31px 0;
}
.banner-wrap .banner-content ul.banner-list li {
    margin: 5px 0;
    display: flex;
    align-items: center;
}
.banner-wrap .banner-content ul.banner-list li i {
    color: var(--font-color);
    margin: 0 10px 0 0;
}
.banner-wrap .banner-content .banner-brands {
    padding: 10px 20px;
    width: 54%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 30px 0 0;
    border-radius: 100px;
    background: #241740;
}
.banner-wrap .banner-content .banner-brands img {
    margin: 0 10px;
    width: 100px;
    height: 80px;
    object-fit: contain;
}
.banner-wrap .banner-form {
    background: #fff;
    border: 5px solid var(--font-color);
    border-radius: 20px;
    padding: 30px;
    text-align: center;
    width: 92%;
}
.banner-wrap .banner-form .banner-form span {
    color: var(--font-color);
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 10px;
    font-weight: 800;
}
.banner-wrap .banner-form .banner-form h3 {
    color: #212529;
    font-size: 26px;
    line-height: 34px;
    margin: 0 0 20px;
}
.banner-wrap .banner-form .form-group input {
    color: #212529;
    height: 50px;
}
.banner-wrap .banner-form .form-group input,
.banner-wrap .banner-form .form-group textarea {
    background: 0 0;
    font-size: 17px;
    padding: 0;
    border-radius: 0;
    border-width: 0 0 2px;
    border-style: solid;
    border-color: var(--font-color);
    outline: 0;
    width: 100%;
    margin: 19px 0;
}
.banner-wrap .banner-form .form-group button.submit-btn {
    color: #fff;
    font-size: 18px;
    border: none;
    width: 100%;
    border-radius: 50px;
    padding: 10px 20px;
    transition: .3s ease-in-out;
}
.banner-wrap  .banner-btns button.popup-btn,
.banner-wrap  .banner-form .form-group button.submit-btn {
    background: linear-gradient(to right,rgb(187 23 47) 0,#e77c1d 100%);
    text-transform: capitalize;
}
.banner-wrap .banner-form .form-group textarea {
    color: #212529;
    height: 100px;
    resize: none;
}
.banner-wrap .banner-form span{
  color: var(--font-color);
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 10px;
    font-weight: 800;
}

.banner-wrap .banner-form h3 {
    color: #212529;
    font-size: 29px;
    line-height: 40px;
    margin: 0 0 20px;
}
.platform{
    margin: 4rem 0;
}
.platform .platform_images{}
.platform .platform_images h3{
    color: #e26f20;
    font-weight: 200;
    text-align: center;
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 2rem 0;
}
.platform .platform_images .wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 51px 0 19px;
}
.platform .platform_images .wrap img{
    width: 143px;
    margin: 0 18px;
    height: 46px;
    object-fit: scale-down;
}
.platform .company-detail-box{
    background: #fff;
    padding:20px 0;
    margin: 3rem 0 0 0;
    text-align: center;
    border-radius: 13px;
    height: 207px;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}
.platform .company-detail-box:hover{
  transform: translateY(-10px);
}
.platform .company-detail-box img{
    width: 48px;
    object-fit: scale-down;
    height: 81px;
}
.platform .company-detail-box h4{
    margin: 12px 0 0;
    font-size: 22px;
    font-weight: 700;
}
.platform .company-detail-box p{
    margin: 0 !important;
    font-size: 23px;
    color: var(--font-color);
}
.services h2{
    color: #241740;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 6rem 0 2rem;
    position: relative;
}
.services h2::after{
  position: absolute;
  content: '';
  background: url('./assets/images/line.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position:100% 100%;
  left: 0;
  width: 451px;
  right: 0;
  height: 100px;
  margin: 0 auto;
  filter: brightness(0) saturate(100%) invert(49%) sepia(32%) saturate(2105%) hue-rotate(356deg) brightness(104%) contrast(81%)
}
.services .service-box {
    padding: 2rem;
    border-radius: 15px;
    height: 380px;
    margin: 20px 0;
    background: #fff;
    transition: all 0.4s ease-in-out;
}

.services .service-box:hover{
  transform: translateY(-14px);
  color: #fff;
  background: #f4f4f4;
}
.services .service-box img{
    width: 56px;
    margin: 7px 0 20px;
}
.services .service-box h4{
    font-size: 25px;
    font-weight: 700;
    color: var(--font-color);
}
.services .service-box .ser-learn-more{
}
.services .service-box .ser-learn-more p{
    margin: 25px 0;
    font-size: 16px;
    height: 114px;
    color: #241740;
}
.services .service-box .ser-learn-more a{
    font-size: 16px;
    background: #221a42;
    padding: 14px 18px;
    border-radius: 100px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    border: none;
}
.services .service-box .ser-learn-more i{
    background: var(--primary-color);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    font-size: 14px;
    margin: 0 0 0 3px;
}
.award_winning{
    background: linear-gradient(90deg,rgb(225 110 31) 0,rgba(255,255,255,.22172619047619047) 31%,rgb(255 255 255) 65%,rgb(255 178 126) 81%);
    border-radius: 20px;
    padding: 2rem 0;
    margin: 3rem 0;
}
.award_winning h3{
    color: #d5323c;
    font-weight: 200;
    text-align: center;
    font-size: 42px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 1rem 0 2rem;
}
.award_winning img{
    margin: 0 46px;
    width: 86px;
}




.cta {
    background: url(./assets/images/cta-banner.png) 0 0/cover no-repeat;
    padding: 2rem 0;
    margin: 0 0 2rem;
}
.cta .cta-bg {
 padding: 2rem 3rem;
 background: #fff;
 border-radius: 2rem;
}
.cta img{
  width: 100%;
  animation: 2s infinite alternate scrollDownAnimation;
}
.cta h3{
    color: #e26f20;
    font-weight: 200;
    font-size: 43px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 1rem 0;
}
.cta p{
    margin: 25px 0;
    font-size: 20px;
    color: #241740;
}
.cta button{
  font-size: 18px;
  background: #221a42;
  padding: 3px 38px;
  border-radius: 100px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  border: none;
  background: var(--primary-color);
}
.cta a{
      background-color: #221a42;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      color: #fff;
}


.our-process {
    background: url(./assets/images/process-img.avif);
    height: 1429px;
    background-position: center;
    background-size: 43%;
    background-repeat: no-repeat;
}
.our-process  .head_area h2{
    color: #241740;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 6rem 0 2rem;
    position: relative;
}
.our-process  .head_area h2::after{
  position: absolute;
  content: '';
  background-size: cover;
  background-repeat: no-repeat;
  background-position:100% 100%;
  left: 0;
  width: 451px;
  right: 0;
  height: 100px;
  margin: 0 auto;
  filter: brightness(0) saturate(100%) invert(49%) sepia(32%) saturate(2105%) hue-rotate(356deg) brightness(104%) contrast(81%)
}


.our-process  .head_area p{
    margin: 25px 0;
    font-size: 16px;
    color: #241740;
}
.our-process .our-process-step.step1 {
    padding-top: 75px;
    text-align: right;
}
.our-process .our-process-step p {
    font-size: 17px;
    font-weight: 300;
}
.our-process .our-process-step h4 {
    font-size: 33px;
    font-weight: 600;
    color: var(--font-color);
}
.our-process  .step2 {
    padding-top: 343px;
}
.our-process-step.step4 {
    padding-top: 250px;
    padding-left: 20px;
}


.tabs_portfolio .tab-content{
	margin: 2rem 1rem;
}
.tabs_portfolio ul{
    justify-content: center;
    border-bottom: none;
    margin: 44px 0;
}
.tabs_portfolio ul li{
    margin: 9px 15px;
    border-radius: 45px;
    overflow: hidden;
}
.tabs_portfolio ul li button.active{
	background: #241740 !important;
	color:#fff !important;
	transition: all 0.3s ease-in-out;
}
.tabs_portfolio ul li button{
	font-size: 18px;
	background: var(--primary-color) !important;
	border-radius: 35px;
	line-height: 0;
	width: 186px;
	padding: 25px 0;
	color: #fff;
	font-weight: 500;
	text-transform: uppercase;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !IMPORTANT;
	border-radius: 0;
}
.tabs_portfolio ul li button:hover{
	color: #fff;
}
.tabs_portfolio ul li button::after,
.tabs_portfolio ul li button::before{
	z-index: -2;
	border-radius: 2px;
	width: 136px;
	height: 7px;
	top: 0;
}
.tabs_portfolio .tab-content .portfolio{
    margin: 4px 4px;
}
.tabs_portfolio .tab-content .portfolio a{
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    display: block;
}

.tabs_portfolio .tab-content .portfolio a::after {
    content: '';
    position: absolute;
    width: 100%;
    background: linear-gradient(45deg, #ed6f10b0, #d7363a9c);
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: all 1s ease-in-out;
}
.tabs_portfolio .tab-content .portfolio a:hover::after {
	opacity: 1;
}

.tabs_portfolio .tab-content .portfolio a img {
	width: 100%;
	display: block;
	min-height: 400px;
	max-height: 400px;
	object-fit: cover;
	object-position: top;
	transition: all 3s ease-in-out;
}
.tabs_portfolio .tab-content .portfolio a:hover img{
    object-position: bottom;
}
.tabs_portfolio .tab-content .col-xl-3{
	padding: 0;
}



.why_choose{
    margin: 2rem 0 1rem;
}
.why_choose h2{
    color: #e26f20;
    font-weight: 200;
    font-size: 43px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 1rem 0;
}
.why_choose p{
    margin: 25px 0;
    font-size: 20px;
    color: #241740;
}
.why_choose img{
    width: 68%;
    animation: 2s infinite alternate scrollDownAnimation;
}


.ft_form{
    position: relative;
    top: 3rem;
}
.ft_form .form-bg, .tabs_content a {
    background: linear-gradient(to right,rgb(187 23 47) 0,#e77c1d 100%);
    color: #fff;
    padding: 40px 90px 40px 50px;
    border-radius: 30px;
}

.ft_form h3 {
    font-size: 67px;
    font-weight: 800;
    line-height: 72px;
    margin: 0 0 10px 0;
}
.ft_form p,
.overlay h4{
    color: #fff;
    font-size: 20px;
}
.ft_form .form-control {
    background: #ffffff36;
    border-bottom: 1px solid #fff;
    color: #fff;
    box-shadow: none;
    margin: 20px 0;
    padding: 13px 20px;
    font-size: 19px;
    outline: none;
}
.ft_form .form-select{
    background: #ffffff36;
    border-bottom: 1px solid #fff;
        box-shadow: none;
    margin: 20px 0;
    padding: 13px 20px;
    font-size: 19px;
    color: #fff;

    outline: none;

}
.ft_form .form-select option{
  color: #000;
}
.ft_form .form-control::placeholder{
  color:#fff;
}
.ft_form .form-btn {
    font-size: 16px;
    background-color: #fff;
    border: 0;
    border-radius: 44px;
    width: 169px;
    padding: 10px 0;
    font-weight: 600;
    text-transform: uppercase;
    margin: 1px 0 0 0;
}
.faq-sec{}

.faq-sec .head_txt{}
.faq-sec .head_txt h2{
      color: #241740;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 6rem 0 2rem;
      position: relative;
}
.faq-sec .head_txt h2::after{
position: absolute;
    content: '';
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    left: 0;
    width: 451px;
    right: 0;
    height: 100px;
    margin: 0 auto;
    filter: brightness(0) saturate(100%) invert(49%) sepia(32%) saturate(2105%) hue-rotate(356deg) brightness(104%) contrast(81%);
}
.faq-sec .head_txt p{
      margin: 25px 0;
    font-size: 20px;
    color: #241740;
}
.faq-sec .faq-content {
    background-color: #eee;
    margin-top: 30px;
    position: relative;
    border-radius: 10px;
    height: 536px;
    padding: 1rem 3rem;
}
.faq-sec .faq-content h4{
      font-size: 133px;
      font-weight: 200;
      color: #e26f20;
}
.faq-sec .faq-content  p{
      font-size: 20px;
      font-weight: 700;
      padding-top: 96px;
}
.faq-sec .faq-content:hover h4,
.faq-sec .faq-content:hover p{
  color: #fff;
}
.faq-sec .overlay {
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    height: 0;
    padding: 0 2rem;
    position: absolute;
    width: 100%;
    z-index: 1000;
    transition: .5s;
}
.faq-sec .faq-content:hover .overlay{
    background: linear-gradient(to right,rgb(187 23 47) 0,#e77c1d 100%);
    height: 100%;
}


.testimonials h2{
      color: #241740;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 6rem 0 2rem;
    position: relative;
}
.testimonials h2::after{
      position: absolute;
    content: '';
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    left: 0;
    width: 451px;
    right: 0;
    height: 100px;
    margin: 0 auto;
    filter: brightness(0) saturate(100%) invert(49%) sepia(32%) saturate(2105%) hue-rotate(356deg) brightness(104%) contrast(81%);
}
.testimonials p{
      margin: 3rem 0;
      font-size: 16px;
      color: #241740;
}
.testimonials ul{
    border: none;
}
.testimonials ul li{
    margin: 0 13px;
}
.testimonials ul li button.active{
    background: var(--primary-color);
    color: #fff !important;
}
.testimonials ul li button:hover{
  color: #241740;
}
.testimonials ul li button{
    font-size: 16px;
    padding: 3px 38px;
    border-radius: 100px;
    font-weight: 600;
    color: #241740;
    text-transform: uppercase;
    border: none;
    border-radius: 0;
    padding: 11px 20px;
}
.testimonials .review_box p{
    background: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    width: 60%;
    font-size: 18px;
    margin: 2rem auto;
}
.testimonials .review_box .img_area{
  display: flex;
}

.inner-banner-sec {
  padding: 100px 0;
  overflow: hidden;
}

.inner-banner-sec .banner-content h1 {
  font-size: 55px;
  line-height: 71px;
  color: var(--font-color);
  font-weight: 300;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 8rem 0 0 0 ;
  padding: 0;
}

.inner-banner-sec .banner-content h2 {
  font-size: 35px;
  line-height: 42px;
  color: #212529;
  font-weight: 300;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.inner-banner-sec .banner-content p {
  font-size: 19px;
  line-height: 28.5px;
  color: #212529;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 20px 0 0 0;
  padding: 0;
}

.inner-banner-sec .banner-content .brand-rating {
  margin: 80px 0 0 0;
}

.inner-banner-sec .banner-content .brand-rating .rating {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  width: 410px;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
  padding: 10px 0;
  margin: 0 0 -26px 0;
  z-index: 1;
  position: relative;
}

.inner-banner-sec .banner-content .brand-rating .rating h6 {
  font-size: 20px;
  line-height: 30px;
  color: #212529;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.inner-banner-sec .banner-content .brand-rating .rating ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2px;
}

.inner-banner-sec .banner-content .brand-rating .rating ul li i {
  color: #ffc200;
  font-size: 20px;
  font-weight: 900;
}

.inner-banner-sec .banner-content .brand-rating .brand-img {
  padding: 38px 0 21px;
  border-radius: 60px;
  justify-content: center;
  display: flex;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
}

.inner-banner-sec .banner-content .brand-rating .brand-img img {
  min-width: 450px;
  max-width: 450px;
  min-height: 25.65px;
  max-height: 35.65px;
  object-fit: contain;
}

.inner-banner-sec .banner-content .banner-btn {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 3rem 0 0 0;
  gap: 20px;
}

.inner-banner-sec .banner-content .banner-btn .btn-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0 30px;
  height: 60px;
  border-radius: 100px;
  background: var(--primary-color);
  transition: all .5s ease-in-out;
}

.inner-banner-sec .banner-content .banner-btn .btn-contact:hover {
  transform: scale(1.1);
}

.inner-banner-sec .banner-content .banner-btn .btn-chat {
  background-color: var(--secondry-color);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 60px;
  color: #fff;
  justify-content: center;
  font-size: 20px;
  line-height: 20px;
}

.inner-banner-sec .main-img img {
  min-width: 100%;
  max-width: 100%;
  min-height: 510px;
  max-height: 510px;
  object-fit: contain;
  margin-top: 5rem ;
}


.inner-banner-sec .banner-content .banner-form {
  margin: 50px 0 0 0;
}

.inner-banner-sec .banner-content .banner-form .form-group .form-control {
  width: 100%;
  height: 62px;
  border: 1px solid #fff;
  padding: 0 15px;
  background: #b5b5b54a;
  font-size: 16px;
  border-radius: 10px;
  outline: 0;
  font-family: Cabin, sans-serif;
  color: #212529;
}

.inner-banner-sec .banner-content .banner-form .form-group .form-control::placeholder {
  font-size: 16px;
  line-height: 16px;
  color: #212529;
  opacity: 0.5;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.inner-banner-sec .banner-content .banner-form .form-group .form-control:focus {
  box-shadow: none;
}

.inner-banner-sec .banner-content .banner-form .form-btn .btn-form {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0 auto;
  padding: 0 20px;
  height: 62px;
  border-radius: 10px;
  background: var(--primary-color);
  transition: all .5s ease-in-out;
  width: 100%;
}


.about-sec-wrap {
  padding: 100px 0;
  overflow: hidden;
}

.about-sec-wrap .content {
  margin: 0 0 60px 0;
}

.about-sec-wrap .content h2 {
  font-size: 26px;
  line-height: 71px;
  color: #212529;
  font-weight: 300;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0 0 50px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.about-sec-wrap .content h3 {
  font-size: 30px;
  line-height: 36px;
  color: #212559;
  font-weight: 500;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 10px 0 10px 0;
  padding: 0;
  text-align: center;
}

.about-sec-wrap .content h2::before {
  position: absolute;
  content: '';
  bottom: 0;
  width: 420px;
  background-image: url('./assets/images/outline.png');
  height: 99px;
  filter: var(--filter);
  background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.about-sec-wrap .content p {
  font-size: 17px;
  line-height: 25.5px;
  color: #1a2b35;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0 0 15px 0;
  padding: 0;
  text-align: center;
}

.about-sec-wrap .about-card {
  border: 3px solid #e16f1f;
  border-radius: 25px;
  transition: all .3s ease-in-out;
  padding: 0;
  min-height: 227px;
  max-height: 227px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
}

.about-sec-wrap .about-card:hover {
  background: var(--primary-color);
}

.about-sec-wrap .about-card:hover .main-img {
  background-color: #000;
}

.about-sec-wrap .about-card:hover .main-img img {
  filter: brightness(0) invert(1);
}

.about-sec-wrap .about-card:hover .desc .btn-call {
  color: #da1e5a;
  background-color: #fff;
}

.about-sec-wrap .about-card .main-img {
  width: 60px;
  height: 60px;
  background: #ff572238;
  border-radius: 60px;
  border: 1px solid #e77d1fc9;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-sec-wrap .about-card h5 {
  font-size: 21px;
  line-height: 25.5px;
  color: #212529;
  font-weight: 700;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.about-sec-wrap .about-card .desc {
  margin: 22px 0 0 0;
}

.about-sec-wrap .about-card .desc h4 {
  font-size: 47px;
  line-height: 56.5px;
  color: #212529;
  font-weight: 700;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.about-sec-wrap .about-card .desc p {
  font-size: 14px;
  line-height: 21px;
  color: #1a2b35;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.about-sec-wrap .about-card .desc .btn-call {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  width: 150px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  padding: 0;
  height: 41px;
  background-color: #1a2b35;
  border-radius: 5px;
  margin: 0 auto;
  gap: 10px;
}

.about-sec-wrap .about-card .desc .btn-call i {
  font-size: 14px;
  font-weight: 900;
}

.Premium-ppc-sec {
  padding: 100px 0;
  overflow: hidden;
}

.Premium-ppc-sec .content {
  margin: 0 0 25px 0;
}

.Premium-ppc-sec .content h3 {
  font-size: 30px;
  line-height: 36px;
  color: #212529;
  font-weight: 300;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.Premium-ppc-sec .content p {
  font-size: 17px;
  line-height: 25.5px;
  color: #1a2b35;
  font-weight: 300;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.Premium-ppc-sec .ppc-card {
  background-color: #f3f3f3;
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  border-radius: 28px;
  min-height: 189px;
  max-height: 189px;
  margin: 0 0 30px 0;
}

.Premium-ppc-sec .ppc-card .main-img {
  background-color: #fff;
  padding: 20px 18px;
  width: 163px;
  height: 97px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Premium-ppc-sec .ppc-card .main-img img {
  filter: var(--filter);
}

.Premium-ppc-sec .ppc-card .desc h4 {
  font-size: 30px;
  line-height: 36px;
  color: #212529;
  font-weight: 500;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0 0 10px 0;
  padding: 0;
}

.Premium-ppc-sec .ppc-card .desc p {
  font-size: 14px;
  line-height: 21px;
  color: #1a2b35;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.profund-sec-wrap {
  padding: 100px 0;
  overflow: hidden;
}

.profund-sec-wrap .content {
  margin: 0 0 60px 0;
}

.profund-sec-wrap .content h2 {
  font-size: 26px;
  line-height: 71px;
  color: #212529;
  font-weight: 300;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0 0 50px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.profund-sec-wrap .content h2::before {
  position: absolute;
  content: '';
  bottom: 0;
  width: 420px;
  background-image: url('./assets/images/outline.png');
  height: 99px;
  filter: var(--filter);
  background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.profund-sec-wrap .profund-card {
  border: 2px solid #841c4f;
  border-radius: 200px 200px 30px 30px;
  padding: 16px;
  position: relative;
  z-index: -1;
}

.profund-sec-wrap .profund-card .step {
  position: absolute;
  top: -25px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.profund-sec-wrap .profund-card .step h5 {
  font-size: 30px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  text-align: center;
  padding: 0;
}

.profund-sec-wrap .profund-card .inner-box {
  background-color: #fff;
  padding: 45px 20px;
  border-radius: 200px 200px 30px 30px;
  position: relative;
  min-height: 500px;
  max-height: 500px;
}

.profund-sec-wrap .profund-card .inner-box .main-img {
  background-color: #ff57223b;
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 30px;
}

.profund-sec-wrap .profund-card .inner-box .main-img img {
  filter: var(--filter);
}

.profund-sec-wrap .profund-card .inner-box .desc h4 {
  font-size: 30px;
  line-height: 36px;
  color: #212529;
  font-weight: 500;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0 0 10px 0;
  text-align: center;
  padding: 0;
}

.profund-sec-wrap .profund-card .inner-box .desc p {
  font-size: 16px;
  line-height: 25px;
  color: #212529;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.profund-sec-wrap .profund-card .inner-box .bg-img {
  position: absolute;
  top: -60px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: -999;
}

.profund-sec-wrap .profund-card .inner-box .bg-img img {
  min-width: 100%;
  max-width: 100%;
  object-fit: fill;
  filter: var(--filter);
  min-height: 100%;
  max-height: 100%;
}


.award-sec-wrap {
  padding: 100px 0;
  overflow: hidden;
}

.award-sec-wrap .award-box {
  background-color: #f3f3f3;
  padding: 20px 40px 60px;
  border-radius: 10px;
}

.award-sec-wrap .award-box .title {
  margin: 0 0 30px 0;
}

.award-sec-wrap .award-box .title h2 {
  font-size: 35px;
  line-height: 42px;
  color: #212529;
  font-weight: 100;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.award-sec-wrap .award-box .awrad-list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
}

.award-sec-wrap .award-box .awrad-list .awrd-img {
  background: #fff;
  border-radius: 60px;
}

.award-sec-wrap .award-box .awrad-list .awrd-img img {
  min-width: 93px;
  max-width: 93px;
  min-height: 93px;
  max-height: 93px;
  object-fit: contain;
}


.pricing-sec-wrap {
  padding: 23px 0;
  overflow: hidden;
}


.pricing-sec-wrap .content h2 {
    font-size: 34px;
    line-height: 71px;
    color: #212529;
    font-weight: 600;
    font-family: Cabin, sans-serif;
    word-spacing: 0px;
    letter-spacing: 0px;
    margin: 0;
    padding: 0 0 50px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    text-transform: uppercase;

}

.pricing-sec-wrap .content h2::before {
  position: absolute;
  content: '';
  bottom: 0;
  width: 420px;
  background-image: url('./assets/images/outline.png');
  height: 99px;
  filter: var(--filter);
}


.pricing-sec-wrap .pricing-top-btn .btn-top-pricing {
  display: flex;
  width: 205px;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  line-height: 19px;
  color: #fff;
  font-weight: 900;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0 auto;
  padding: 0 30px;
  height: 56.5px;
  border-radius: 100px;
  background: var(--primary-color);
  transition: all .5s ease-in-out;
}

.pricing-sec-wrap .pricing-top-btn .btn-top-pricing:hover {
  transform: scale(1.1);
}

.pricing-sec-wrap .nav {
  justify-content: center;
  gap: 15px;
  margin: 0 0 50px 0 !important;
}


.pricing-sec-wrap .nav .nav-item .nav-link {
  font-weight: 800;
  color: #221a42;
  font-size: 19px;
  background-color: #fff;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
  border-radius: 38px;
  line-height: 19px;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  height: 56.5px;
  transition: all .3s ease-in-out;
}

.pricing-sec-wrap .nav .nav-item .nav-link.active {
  background: var(--primary-color);
  color: #fff;
}

.pricing-sec-wrap .pricing-card {
  padding: 0 15px;
  position: relative;
  margin: 150px 0 0 0;
}

.pricing-sec-wrap .pricing-card .main-box img {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  filter: var(--filter);
}

.pricing-sec-wrap .pricing-card .main-box {
  position: absolute;
  top: -90px;
  left: 0;
  width: 100%;
  z-index: -1;
}

.pricing-sec-wrap .pricing-card .title h2 {
  font-size: 30px;
  line-height: 36px;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0 auto 30px;
  padding: 0;
  text-align: center;
  width: 290px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-sec-wrap .pricing-card .inner-box {
  background-color: #fff;
  border-radius: 30px;
  padding: 20px 10px;
}

.pricing-sec-wrap .pricing-card .inner-box h5 {
  font-size: 22px;
  line-height: 26.4px;
  color: #212529;
  font-weight: 500;
  text-transform: uppercase;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.pricing-sec-wrap .pricing-card .inner-box h4 {
  font-size: 50px;
  line-height: 75px;
  color: #e4741e;
  font-weight: 700;
  text-transform: uppercase;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.pricing-sec-wrap .pricing-card .inner-box ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 6px;
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
}

.pricing-sec-wrap .pricing-card .inner-box ul::-webkit-scrollbar {
  width: 5px;
}

.pricing-sec-wrap .pricing-card .inner-box ul::-webkit-scrollbar-track {
  background: #ebebeb;
}

.pricing-sec-wrap .pricing-card .inner-box ul::-webkit-scrollbar-thumb {
  background: #e4741e;
  border-radius: 10px;
}

.pricing-sec-wrap .pricing-card .inner-box ul li {
  font-size: 18px;
  line-height: 27px;
  color: #212529;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0 0 0 20px;
  position: relative;
  opacity: 0.9;
}

.pricing-sec-wrap .pricing-card .inner-box ul li::before {
  position: absolute;
  content: '\e122';
  top: 0;
  left: 0;
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  font-size: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-sec-wrap .pricing-card .inner-box .delivery {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
  border-radius: 10px;
  padding: 7px;
  text-align: center;
  margin-top: 50px;
}

.pricing-sec-wrap .pricing-card .inner-box .delivery p {
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.pricing-sec-wrap .pricing-card .inner-box .pricing-btns {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 30px 0 0 0;
  gap: 8px;
  justify-content: center;
}

.pricing-sec-wrap .pricing-card .inner-box .pricing-btns .btn-pricing {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0 30px;
  height: 45px;
  border-radius: 100px;
  background: var(--primary-color);
  transition: all .5s ease-in-out;
}

.pricing-sec-wrap .pricing-card .inner-box .pricing-btns .btn-pricing:hover {
  transform: scale(1.1);
}


.clients-review-sec {
  padding: 100px 0;
  overflow: hidden;
}

.clients-review-sec .content {
  margin: 0 0 60px 0;
}

.clients-review-sec .content h2 {
  font-size: 26px;
  line-height: 71px;
  color: #212529;
  font-weight: 300;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0 0 50px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.clients-review-sec .content h2::before {
  position: absolute;
  content: '';
  bottom: 0;
  width: 420px;
  background-image: url('./assets/images/outline.png');
  height: 99px;
  filter: var(--filter);
}


.clients-review-sec .reviews-main .nav {
  justify-content: center;
  gap: 15px;
  margin: 0 0 50px 0 !important;
}


.clients-review-sec .reviews-main .nav .nav-item .nav-link {
  font-weight: 800;
  color: #221a42;
  font-size: 19px;
  background-color: #fff;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
  border-radius: 38px;
  line-height: 19px;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  height: 56.5px;
  transition: all .3s ease-in-out;
}

.clients-review-sec .reviews-main .nav .nav-item .nav-link.active {
  background: var(--primary-color);
  color: #fff;
}

.clients-review-sec .reviews-main .tab-content .client-box {
  border-radius: 20px;
  padding: 35px 20px;
  background-color: #fff;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
  margin: 0 0 30px 0;
}

.clients-review-sec .reviews-main .tab-content .client-box .box-top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  margin: 0 0 30px 0;
}


.clients-review-sec .reviews-main .tab-content .client-box .box-top .main-img img {
  min-width: 76px;
  max-width: 76px;
  min-height: 76px;
  max-height: 76px;
  object-fit: cover;
}

.clients-review-sec .reviews-main .tab-content .client-box .box-top .info h4 {
  font-size: 20px;
  line-height: 24px;
  color: #212529;
  font-weight: 500;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.clients-review-sec .reviews-main .tab-content .client-box .box-top .info ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}

.clients-review-sec .reviews-main .tab-content .client-box .box-top .info ul li i {
  color: #ffc200;
  font-size: 12px;
  font-weight: 900;
}

.clients-review-sec .reviews-main .tab-content .client-box .box-bottom p {
  font-size: 18px;
  line-height: 27px;
  color: #212529;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0 10px;
  text-align: center;
  min-height: 200px;
  max-height: 200px;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clients-review-sec .reviews-main .tab-content .client-box .box-bottom p::-webkit-scrollbar {
  width: 5px;
}

.clients-review-sec .reviews-main .tab-content .client-box .box-bottom p::-webkit-scrollbar-track {
  background: #ebebeb;
}

.clients-review-sec .reviews-main .tab-content .client-box .box-bottom p::-webkit-scrollbar-thumb {
  background: #e4741e;
  border-radius: 10px;
}

.contact-sec-wrap {
  padding: 100px 0;
  overflow: hidden;
}

.contact-sec-wrap .contact-info h2 {
  font-size: 60px;
  line-height: 72px;
  color: var(--font-color);
  font-weight: 500;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.contact-sec-wrap .contact-info h3 {
  font-size: 29px;
  line-height: 34px;
  color: #212529;
  font-weight: 500;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 10px 0 10px 0;
  padding: 0;
}

.contact-sec-wrap .contact-info p {
  font-size: 17px;
  line-height: 25.5px;
  color: #1a2b35;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0 0 15px 0;
  padding: 0;
}

.contact-sec-wrap .contact-info .form-group {
  margin: 30px 0 0 0;
}

.contact-sec-wrap .contact-info .form-group .form-control,
.contact-sec-wrap .contact-info .form-group .form-select {
  border-bottom: 2px solid #e3731f !important;
  border-radius: 0px;
  border: none;
  padding: 5px 5px 30px;
  font-size: 17px;
  line-height: 25.5px;
  color: #1a2b35;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
}

.contact-sec-wrap .contact-info .form-group .form-control::placeholder {
  font-size: 20px;
  line-height: 20px;
  color: #212529;
  font-weight: 500;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.contact-sec-wrap .contact-info .form-check {
  margin: 20px 0 0 0;
}

.contact-sec-wrap .contact-info .form-check .form-check-label {
  font-size: 15px;
  line-height: 22px;
  color: #1a2b35;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.contact-sec-wrap .contact-info .contact-btn {
  margin: 30px 0 0 0;
}

.contact-sec-wrap .contact-info .contact-btn .btn-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0 30px;
  height: 60px;
  width: 254px;
  border-radius: 10px;
  background: var(--primary-color);
  transition: all .5s ease-in-out;
}

.contact-sec-wrap .contact-info .contact-btn .btn-contact:hover {
  transform: scale(1.1);
}


.details-sec-wrap {
  padding: 100px 0;
  overflow: hidden;
}

.details-sec-wrap .information h4 {
  font-size: 20px;
  line-height: 24px;
  color: #e3741e;
  font-weight: 500;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0 0 5px 0;
  padding: 0;
}

.details-sec-wrap .information .info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
}

.details-sec-wrap .information .info i {
  width: 30px;
  height: 30px;
  background-color: #e6791e5c;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 100%;
  color: var(--font-color);
}

.details-sec-wrap .information .info a {
  color: var(--font-color);
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
  text-transform: unset;
  font-family: unset;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  transition: all .3s ease-in-out;
}

.details-sec-wrap .information .info p {
  color: var(--font-color);
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
  text-transform: unset;
  font-family: unset;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  width: 370px;
}

.details-sec-wrap .information .info a:hover {
  color: #df4b29;
}


.polices-banner {
  padding: 8rem 0 5rem;
  background: #d33f2f;
}

.polices-banner .title h1 {
  font-size: 55px;
  line-height: 71px;
  color: #fff;
  font-weight: 300;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.main-privacy-sec {
  padding: 100px 0 0;
}

.main-privacy-sec .term-text h3 {
  font-size: 30px;
  line-height: 36px;
  color: #212529;
  font-weight: 700;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 10px 0 10px 0;
  padding: 0;
}

.main-privacy-sec .term-text h5 {
  font-size: 18px;
  line-height: 22px;
  color: #212529;
  font-weight: 700;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 10px 0 10px 0;
  padding: 0;
}

.main-privacy-sec .term-text p {
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0 0 15px 0;
  padding: 0;
}

.main-privacy-sec .term-text p a {
  color: var(--font-color);
}


.inner-banner-sec.big-banner {
  background-repeat: no-repeat;
  background: url(./assets/images/about-us-img.jpg);
  background-size: cover;
  background-position: 100% 15%;
  min-height: 1200px;
  max-height: 1200px;
}

.inner-banner-sec.big-banner .banner-content h1 {
  text-align: center;
}

.inner-banner-sec.big-banner .banner-content p {
  text-align: center;
}

.inner-banner-sec.big-banner .banner-content .banner-btn {
  justify-content: center;
}


.vision-sec-warp {
  padding: 100px 0;
}

.vision-sec-warp .desc h2 {
  font-size: 35px;
  line-height: 42px;
  color: #212529;
  font-weight: 100;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0 0 10px 0;
  padding: 0;
}

.vision-sec-warp .desc p {
  font-size: 17px;
  line-height: 25.5px;
  color: #1a2b35;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.book-sec-wrap {
  padding: 100px 0;
  overflow: hidden;
}

.book-sec-wrap .content h2 {
  font-size: 44px;
  line-height: 55px;
  color: #212529;
  font-weight: 700;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.book-sec-wrap .content p {
  font-size: 20px;
  line-height: 30px;
  color: #212529;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  text-align: center;
  margin: 20px 0 0 0;
  padding: 0;
}

.book-sec-wrap .book-card .mian-img img {
  min-width: 100%;
  max-width: 100%;
  max-height: 227px;
  min-height: 227px;
  object-fit: contain;
}


.book-sec-wrap .book-card .desc h4 {
  font-size: 19px;
  line-height: 22.8px;
  color: #212529;
  font-weight: 900;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.book-sec-wrap .book-card .desc p {
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 5px 0 0 0;
  padding: 0;
}


.professional-sec-wrap {
  padding: 100px 0;
  overflow: hidden;
  background: #e8e8e8;
}

.professional-sec-wrap .content h2 {
  font-size: 40px;
  line-height: 51px;
  color: #e4751e;
  font-weight: 800;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.professional-sec-wrap .content p {
  font-size: 18px;
  line-height: 27px;
  color: #1a2b35;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 20px 0 0 0;
  padding: 0;
}

.professional-sec-wrap .content .professional-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin: 30px 0 0 0;
}

.professional-sec-wrap .content .professional-btn .btn-professional {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0 25px;
  height: 46px;
  gap: 10px;
  border-radius: 100px;
  background: var(--primary-color);
  transition: all .5s ease-in-out;
}

.professional-sec-wrap .main-img img {
  min-height: 505px;
  max-height: 505px;
  min-width: 100%;
  max-width: 100%;
  object-fit: contain;
}


.cta-sec-wrap {
  padding: 60px 0;
  background-image: url('./assets/images/cta-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}

.cta-sec-wrap .cta-img {
  display: flex;
  justify-content: flex-end;
}

.cta-sec-wrap .cta-img img {
  min-width: 206px;
  max-width: 206px;
  min-height: 211px;
  max-height: 211px;
  object-fit: contain;
}

.cta-sec-wrap .cta-content h4 {
  font-size: 28px;
  line-height: 34px;
  color: #212529;
  font-weight: 700;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.cta-sec-wrap .cta-content p {
  font-size: 18px;
  line-height: 21px;
  color: #151515;
  font-weight: 600;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 10px 0 0 0;
  padding: 0;
}

.cta-sec-wrap .cta-content .cta-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0 0 0;
}

.cta-sec-wrap .cta-content .cta-btn .btn-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0 20px;
  height: 45px;
  border-radius: 0px;
  background: #141414;
  transition: all .5s ease-in-out;
}

.cta-sec-wrap .cta-content .cta-btn .btn-cta:nth-child(2) {
  background: #fff;
  color: #141414;
}

.cta-sec-wrap .cta-content .cta-number {
  margin: 20px 0 0 0;
}

.cta-sec-wrap .cta-content .cta-number a {
  font-size: 16px;
  line-height: 24px;
  color: #151515;
  font-weight: 600;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}


.tier-sec-wrap {
  padding: 100px 0;
  overflow: hidden;
}

.tier-sec-wrap .title {
  margin: 0 0 50px 0;
}

.tier-sec-wrap .title h2 {
  font-size: 34px;
  line-height: 42px;
  color: #212529;
  font-weight: 700;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.tier-sec-wrap .tier-card {
  padding: 30px 30px;
  background: #ebebeb;
  transition: all .5s ease-in-out;
  border-radius: 14px;
  min-height: 372px;
  max-height: 372px;
  transition: all .5s ease-in-out;
}

.tier-sec-wrap .tier-card:hover {
  transform: translateY(-27px);
}

.tier-sec-wrap .tier-card.active {
  background: var(--primary-color);
}

.tier-sec-wrap .tier-card.active .desc h4,
.tier-sec-wrap .tier-card.active .desc p {
  color: #fff;
}

.tier-sec-wrap .tier-img {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 30px 0;
}

.tier-sec-wrap .tier-img img {
  min-width: 77px;
  max-width: 77px;
  min-height: 77px;
  max-height: 77px;
  object-fit: contain;
}

.tier-sec-wrap .desc h4 {
  font-size: 30px;
  line-height: 36px;
  color: #000000;
  font-weight: 700;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.tier-sec-wrap .desc p {
  font-size: 16px;
  line-height: 25px;
  color: #212529;
  font-weight: 300;
  text-transform: unset;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 20px 0 0 0;
  padding: 0;
}




.timeline-sec {
  padding: 4rem 0;
}

.timeline-sec .card,
.timeline-sec .timelineSec__box {
  position: relative;
}

.timeline-sec .heading {
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0;
  color: #000
}

.timeline-sec .timelineSec__box:before {
  content: "";
  border: 1px dashed #dd581d;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0
}

.timeline-sec .card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem
}

.timeline-left-arrow:before,
.timeline-sec .timeline-right-arrow:before {
  content: "";
  width: 12px;
  height: 26px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto
}

.timeline-sec .timeline-right-arrow:before {
  background-image: url(./assets/images/right-timeline-arrow.png);
  right: -23.5%;
}

.timeline-left-arrow:before {
  background-image: url(./assets/images/left-timeline-arrow.png);
  left: -23%;
}

.timeline-sec .card-body {
  flex: 1 1 auto;
  padding: 3rem;
  border: 1px solid #eb6914;
  border-radius: 10px
}

.timeline-sec .card-body p {
  font-size: 14px
}

.timeline-sec .card .card-title {
  font-size: 24px;
  color: #262626;
  font-weight: 600
}

.timelineSec__box span.text-orange {
  font-size: 29px;
  padding: 15px;
  color: #ea6617;
  font-weight: 700
}


.cta-form-wrap {
  background-image: url('./assets/images/footer2_top_bg.jpg');
  padding: 100px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 30% 0%;
}

.cta-form-wrap .title {
  margin: 0 0 50px 0;
}

.cta-form-wrap .title h2 {
  font-size: 42px;
  line-height: 50px;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.cta-form-wrap .content .form-group .form-control {
  width: 100%;
  padding: 32px 0;
  background: transparent;
  font-size: 16px;
  border-radius: 10px;
  outline: 0;
  font-family: Cabin, sans-serif;
  color: #fff;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #4f4f4f;
  height: 104px;
}

.cta-form-wrap .content .form-group textarea.form-control {
  height: 208px;
}

.cta-form-wrap .content .form-group .form-control:focus {
  box-shadow: none;

}

.cta-form-wrap .content .form-group .form-control::placeholder {
  font-size: 26px;
  line-height: 39px;
  color: #fff;
  opacity: 0.5;
  font-weight: 300;
  text-transform: capitalize;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
}

.cta-form-wrap .content .cta-form-btn {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
}

.cta-form-wrap .content .cta-form-btn .btn-form {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-family: Cabin, sans-serif;
  word-spacing: 0px;
  letter-spacing: 0px;
  margin: 0 auto 0 0;
  padding: 0 20px;
  height: 50px;
  border-radius: 10px;
  background: #eb6914;
  transition: all .5s ease-in-out;
  width: 107px;
}


.modal{
}
.modal img{
    width: 76%;
    margin: 0 auto;
}
.modal-dialog {
    max-width: 1100px!important;
}
.modal-close-btn {
    position: absolute;
    top: 3%;
    right: 2%;
}
.btn-bg {
    padding: 15px 70px 15px 40px;
    background: linear-gradient(to right,rgb(187 23 47) 0,#e77c1d 100%);
    border: 0;
    border-radius: 100px;
}
.modal-form-wrap .form-control {
    border: 0;
    border-bottom: 2px solid #da5d23;
    font-size: 24px;
    background-color: #e9e9e9;
    margin-top: 24px;
    padding: 15px 18px;
    color: #000;
    border-radius: 9px;
    box-shadow: none;
}
.modal-header {
    border-bottom: 0
}

.discount {
    background: #fff;
    border-radius: 10px;
    padding-left: 10px;
    color: #bf212d;
        text-align: center;

    font-size: 37px;
}

.modal-heading {
    font-weight: 700;
    padding-top: 10px;
    color: #e16f1f;
    text-align: center;
    font-size: 3rem;
}

.modal-form-wrap .form-control {
    border: 0;
    border-bottom: 2px solid #da5d23;
    font-size: 24px;
    background-color: #e9e9e9;
    margin-top: 24px;
    padding: 15px 18px;
    color: #000;
    border-radius: 9px;
    box-shadow: none
}

.modal-content,.pricing-wrap {
    background: #fff;
    border-radius: 30px
}

.modal-form-wrap .form-control::placeholder {
    color: #000
}

.modal-content {
    padding-left: 20px;
    padding-bottom: 30px
}

.modal-footer {
    border-top: 0
}

.modal .btn-contact-form {
    background-color: #fff;
    color: #fff;
    display: block;
    width: 100%
}



@media only screen and (min-width: 1600px) and (max-width: 1920px) {}

@media only screen and (min-width: 1445px) and (max-width: 1599px) {


  .Premium-ppc-sec .ppc-card .main-img {
    width: 165px;
    height: 75px;
  }

  .Premium-ppc-sec .ppc-card .desc h4 {
    font-size: 26px;
    line-height: 30px;
  }

}

@media only screen and (min-width: 1400px) and (max-width: 1444px) {

  .Premium-ppc-sec .ppc-card .main-img {
    width: 165px;
    height: 75px;
  }

  .Premium-ppc-sec .ppc-card .desc h4 {
    font-size: 22px;
    line-height: 25px;
  }

}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {

  .inner-banner-sec .banner-content h1 {
    font-size: 42px;
    line-height: 52px;
  }

  .inner-banner-sec .banner-content .brand-rating {
    margin: 50px 0 0 0;
  }

  .inner-banner-sec .main-img {
    margin: 40px 0 0 0;
  }

  .inner-banner-sec .banner-content .brand-rating .brand-img img {
    min-width: 350px;
    max-width: 350px;
  }

  .inner-banner-sec .main-img img {
    min-height: 320px;
    max-height: 320px;
  }


  .about-sec-wrap {
    padding: 60px 0;
  }

  .about-sec-wrap .about-card {
    margin: 0 0 20px 0;
  }


  .Premium-ppc-sec {
    padding: 60px 0;
  }

  .Premium-ppc-sec .content h3 {
    margin: 0 0 10px 0;
  }

  .Premium-ppc-sec .ppc-card {
    margin: 0 0 30px 0;
    min-height: 210px;
    max-height: 210px;
  }

  .Premium-ppc-sec .ppc-card .main-img {
    width: 165px;
    height: 75px;
  }

  .Premium-ppc-sec .ppc-card .desc h4 {
    font-size: 22px;
    line-height: 25px;
  }

  .Premium-ppc-sec .ppc-card .desc p {
    font-size: 14px;
    line-height: 20px;
  }

  .profund-sec-wrap {
    padding: 60px 0;
  }

  .profund-sec-wrap .content {
    margin: 0;
  }

  .profund-sec-wrap .profund-card {
    margin: 80px 0 0 0;
  }

  .profund-sec-wrap .profund-card .inner-box .desc h4 {
    font-size: 22px;
    line-height: 25px;
  }

  .profund-sec-wrap .profund-card .inner-box .desc p {
    font-size: 16px;
    line-height: 21px;
  }

  .award-sec-wrap {
    padding: 50px 0;
  }


    .pricing-sec-wrap {
      padding: 50px 0;
    }
  
    .pricing-sec-wrap .pricing-card .title h2 {
      font-size: 24px;
      line-height: 30px;
      width: 100%;
    }
  
    .pricing-sec-wrap .pricing-card .inner-box h4 {
      font-size: 45px;
      line-height: 65px;
    }
  
    .pricing-sec-wrap .pricing-card .inner-box .pricing-btns .btn-pricing{
      padding: 0 20px;
      font-size: 17px;
      line-height: 17px;
    }
  
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap {
    padding: 8rem 1rem 2rem;
}
.header {
    padding: 7px 0;
}
  .header header nav.navigation li {
      padding-right: 0;
  }
  .header header .navigation li a {
      font-size: 14px;
      margin: 0 8px;
  }   
  .header header .logo {
      width: 156px;
  }
  .header header .header-btn {
      font-size: 12px;
      padding: 9px 14px;
  }
  .banner-wrap .banner-content .banner-brands {
      padding: 2px 20px;
      width: 76%;
  }
  .banner-wrap .banner-content h1 {
      font-size: 50px;
      line-height: 56px;
  }
  .banner-wrap .banner-content p {
    font-size: 16px;
    width: 86%;
}
.platform .platform_images .wrap img {
    width: 83px;
    margin: 0 18px;
    height: 26px;
}
.platform .platform_images .wrap{
  flex-wrap: wrap;
}
.header header .navigation .dropdown-toggle {
    font-size: 14px;
}
.services .service-box {
    padding: 1rem;
    height: 338px;
  }

.services .service-box .ser-learn-more a {
    font-size: 14px;
    padding: 10px 14px;
}
.services .service-box h4 {
    font-size: 19px;
}
.tabs_portfolio ul li button {
    font-size: 14px;
    width: 136px;
    padding: 20px 0;
}
.tabs_portfolio ul li {
    margin: 9px 8px;
}
.tabs_portfolio .tab-content .portfolio a img {
    min-height: 200px;
    max-height: 200px;
}
.our-process {
    height: 1279px;
    background-size: 50%;
}
.cta h3 {
    font-size: 30px;
    margin: 1rem 0;
}
.ft_form h3 {
    font-size: 44px;
    line-height: 47px;
    margin: 0 0 10px 0;
}
.ft_form p, .overlay h4 {
    font-size: 16px;
}
footer .footer-logo {
    margin-bottom: 17px;
    width: 196px;
}
footer .footer-navigation h3, footer .learn-more-btn2, footer .packages-box-head span {
    font-size: 18px;
}
footer .footer-navigation li a {
    font-size: 13px;
}
.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    width: 100%;
}
.faq-sec .faq-content {
    height: 425px;
}

  .inner-banner-sec .banner-content h1 {
    font-size: 42px;
    line-height: 52px;
  }

  .inner-banner-sec .banner-content .brand-rating {
    margin: 50px 0 0 0;
  }

  .inner-banner-sec .main-img {
    margin: 40px 0 0 0;
  }

  .inner-banner-sec .banner-content .brand-rating .brand-img img {
    min-width: 350px;
    max-width: 350px;
  }

  .inner-banner-sec .main-img img {
    min-height: 320px;
    max-height: 320px;
  }

  .about-sec-wrap {
    padding: 60px 0;
  }

  .about-sec-wrap .about-card {
    margin: 0 0 20px 0;
  }


  .Premium-ppc-sec {
    padding: 60px 0;
  }

  .Premium-ppc-sec .content h3 {
    margin: 0 0 10px 0;
  }

  .Premium-ppc-sec .ppc-card {
    margin: 0 0 30px 0;
  }

  .Premium-ppc-sec .ppc-card .desc h4 {
    font-size: 22px;
    line-height: 25px;
  }

  .Premium-ppc-sec .ppc-card .desc p {
    font-size: 14px;
    line-height: 20px;
  }

  .profund-sec-wrap {
    padding: 60px 0;
  }

  .profund-sec-wrap .content {
    margin: 0;
  }

  .profund-sec-wrap .profund-card {
    margin: 80px 0 0 0;
  }

  .profund-sec-wrap .profund-card .inner-box {
    min-height: 450px;
    max-height: 450px;
  }

  .profund-sec-wrap .profund-card .inner-box .desc h4 {
    font-size: 22px;
    line-height: 25px;
  }

  .profund-sec-wrap .profund-card .inner-box .desc p {
    font-size: 16px;
    line-height: 21px;
  }

  .award-sec-wrap {
    padding: 50px 0;
  }

  .award-sec-wrap .award-box .title h2 {
    font-size: 26px;
    line-height: 30px;
  }

 
  .pricing-sec-wrap {
    padding: 50px 0;
  }

  .pricing-sec-wrap .pricing-card .title h2 {
    font-size: 24px;
    line-height: 30px;
    width: 100%;
  }

  .pricing-sec-wrap .pricing-card .inner-box h4 {
    font-size: 45px;
    line-height: 65px;
  }

  .pricing-sec-wrap .pricing-card .inner-box .pricing-btns .btn-pricing {
    padding: 0 20px;
    font-size: 17px;
    line-height: 17px;
  }
.inner-banner-sec.big-banner {
        background-position: 100% 63%;
    min-height: 659px;
    max-height: 454px;
}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .details-sec-wrap {
    padding: 0 0;
}
.inner-banner-sec.big-banner {
      background-position: 100% 41%;
    min-height: 671px;
    max-height: 469px;
}
   .banner-wrap {
    padding: 8rem 1rem 2rem;
}
.banner-wrap .banner-form {
    width: 79%;
    margin: 2rem auto;
}
.header {
    padding: 7px 0;
}
  .header header nav.navigation li {
      padding-right: 0;
  }
  .header header .navigation li a {
      font-size: 14px;
      margin: 0 8px;
  }   
  .header header .logo {
      width: 156px;
  }
  .header header .header-btn {
      font-size: 12px;
      padding: 9px 14px;
  }
  .banner-wrap .banner-content .banner-brands {
      padding: 2px 20px;
      width: 76%;
  }
  .banner-wrap .banner-content h1 {
      font-size: 58px;
      line-height: 73px;
  }
  .banner-wrap .banner-content p {
    font-size: 21px;
    width: 86%;
}
.platform .platform_images .wrap img {
        width: 66px;
    margin: 0 16px;
    height: 26px;
}
.platform .platform_images .wrap{
  flex-wrap: wrap;
}
.header header .navigation .dropdown-toggle {
    font-size: 14px;
}
.services .service-box {
    padding: 1rem;
    height: 316px;
  }

.services .service-box .ser-learn-more a {
    font-size: 14px;
    padding: 10px 14px;
}
.services .service-box h4 {
    font-size: 19px;
}
.tabs_portfolio ul li button {
    font-size: 12px;
    width: 101px;
    padding: 16px 0;
}
.tabs_portfolio ul li {
    margin: 9px 8px;
}
.tabs_portfolio .tab-content .portfolio a img {
    min-height: 200px;
    max-height: 200px;
}
.our-process {
    height: 1279px;
    background-size: 50%;
}
.cta h3 {
    font-size: 30px;
    margin: 1rem 0;
}
.ft_form h3 {
    font-size: 44px;
    line-height: 47px;
    margin: 0 0 10px 0;
}
.ft_form p, .overlay h4 {
    font-size: 16px;
}
footer .footer-logo {
    margin-bottom: 17px;
    width: 196px;
}
footer .footer-navigation h3, footer .learn-more-btn2, footer .packages-box-head span {
    font-size: 40px;
    margin: 20px 0;
}
footer .footer-navigation li a {
    font-size: 26px;
}
.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    width: 228px;
}
.faq-sec .faq-content {
    height: 364px;
}
.faq-sec .faq-content p {
    padding-top: 30px;
}
.platform .company-detail-box p {
    font-size: 16px;
}
.platform .platform_images h3 {
    font-size: 32px;
    margin: 0;
}
.services .service-box .ser-learn-more p {
    height: 86px;
}
.award_winning img {
    margin: 0 20px;
    width: 63px;
}
.our-process{
  display:none;
}
.testimonials h2 {
    margin: 2rem 0 1rem;
}
.testimonials ul li {
    margin: 0 6px;
}
.why_choose img {
    display: none;
}
.testimonials .review_box p {
    width: 88%;
}
.cta button {
    font-size: 16px;
    padding: 0px 30px;
}
.bar {
    background: linear-gradient(to right, rgb(187 23 47) 0, #e77c1d 100%);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bar a{
  color:#fff;
}
.header .offcanvas .learn-more, .learn-more:hover, .offcanvas-manu.nav ul li a, .service-box:hover, .text {
    font-size: 40px;
}
.header .offcanvas .accordion-button {
    font-size: 40px;
}

  .inner-banner-sec {
    padding: 100px 0 50px 0;
  }

  .inner-banner-sec .banner-content h1 {
    font-size: 42px;
    line-height: 52px;
  }

  .inner-banner-sec .banner-content .brand-rating {
    margin: 50px 0 0 0;
  }

  .inner-banner-sec .main-img {
    margin: 40px 0 0 0;
  }

  .inner-banner-sec .main-img img {
    min-height: 320px;
    max-height: 320px;
  }


  .about-sec-wrap {
    padding: 50px 0;
  }

  .about-sec-wrap .about-card {
    margin: 0 0 20px 0;
  }


  .Premium-ppc-sec {
    padding: 50px 0;
  }

  .Premium-ppc-sec .content h3 {
    margin: 0 0 10px 0;
  }

  .Premium-ppc-sec .ppc-card {
    min-height: 320px;
    max-height: 320px;
    margin: 0 0 30px 0;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .Premium-ppc-sec .ppc-card .main-img {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }

  .Premium-ppc-sec .ppc-card .desc h4 {
    font-size: 22px;
    line-height: 25px;
  }

  .Premium-ppc-sec .ppc-card .desc p {
    font-size: 14px;
    line-height: 20px;
  }


  .profund-sec-wrap {
    padding: 50px 0;
  }

  .profund-sec-wrap .content {
    margin: 0;
  }

  .profund-sec-wrap .profund-card {
    margin: 80px 0 0 0;
  }

  .profund-sec-wrap .profund-card .inner-box {
    padding: 45px 5px;
    min-height: 400px;
    max-height: 400px;
  }

  .profund-sec-wrap .profund-card .inner-box .desc h4 {
    font-size: 22px;
    line-height: 25px;
  }

  .profund-sec-wrap .profund-card .inner-box .desc p {
    font-size: 16px;
    line-height: 21px;
  }


  .award-sec-wrap {
    padding: 50px 0;
  }

  .award-sec-wrap .award-box .title h2 {
    font-size: 26px;
    line-height: 30px;
  }


  .pricing-sec-wrap {
    padding: 50px 0;
  }

  .pricing-sec-wrap .pricing-card .title h2 {
    font-size: 24px;
    line-height: 30px;
    width: 100%;
  }

  .pricing-sec-wrap .pricing-card .inner-box h4 {
    font-size: 45px;
    line-height: 65px;
  }

  .pricing-sec-wrap .pricing-card .inner-box .pricing-btns .btn-pricing {
    padding: 0 10px;
    font-size: 16px;
    line-height: 16px;
  }

}

@media only screen and (min-width: 576px) and (max-width: 767px) {
.vision-sec-warp {
    padding: 38px 0;
}
.vision-sec-warp .desc h2 {
    margin: 20px 0;

  }  
.faq-sec ,
.testimonials{
    overflow-x: hidden;
}
  
   .banner-wrap {
    padding: 6rem 1rem 0;
}
.banner-wrap .banner-form {
    width: 100%;
    margin: 2rem auto;
}
.header {
    padding: 7px 0;
}
  .header header nav.navigation li {
      padding-right: 0;
  }
  .header header .navigation li a {
      font-size: 14px;
      margin: 0 8px;
  }   
  .header header .logo {
      width: 156px;
  }
  .header header .header-btn {
      font-size: 12px;
      padding: 9px 14px;
  }
  .banner-wrap .banner-content .banner-brands {
        padding: 0px 16px;
    width: 100%;
  }
  .banner-wrap .banner-content h1 {
          font-size: 41px;
    line-height: 53px;
  }
   .banner-wrap  .banner-content{
    /* text-align: center; */
  }
  .banner-wrap .banner-content p {
     font-size: 16px;
    width: 100%;
}
.banner-wrap .banner-content ul {
    margin: 30px 0;
    text-align: left;
}
.platform .platform_images .wrap img {
   width: 90px;
    margin: 0 16px;
    height: 26px;
}
.platform .platform_images .wrap{
  overflow: hidden;

}
.header header .navigation .dropdown-toggle {
    font-size: 14px;
}
.services .service-box {
    padding: 1rem;
    height: 316px;
  }

.services .service-box .ser-learn-more a {
    font-size: 14px;
    padding: 10px 14px;
}
.services .service-box h4 {
    font-size: 19px;
}
.tabs_portfolio ul li button {
       font-size: 12px;
    width: 112px;
    padding: 16px 0;
}
.tabs_portfolio ul li {
    margin: 9px 5px;
}
.tabs_portfolio .tab-content .portfolio a img {
    min-height: 331px;
    max-height: 331px;
}
.our-process {
    height: 1279px;
    background-size: 50%;
}
.cta h3 {
  font-size: 22px;
    margin: 1rem 0;
    line-height: 33px;
    text-align: center;
}
.cta p {
    text-align: center;
    margin: 21px 0;
    font-size: 16px;
}
.cta img {
    width: 93%;
    margin: 32px auto 0;
}
.testimonials p {
    margin: 1rem 0;
}
.ft_form h3 {
    font-size: 32px;
    line-height: 47px;
    margin: 0 0 10px 0;
}
.ft_form p, .overlay h4 {
    font-size: 16px;
}
footer .footer-logo {
    margin-bottom: 17px;
    width: 196px;
}
footer .footer-navigation h3, footer .learn-more-btn2, footer .packages-box-head span {
   font-size: 27px;
    margin: 15px 0;
    color: #FF9800;
}
footer .footer-navigation li a {
    font-size: 26px;
}
.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    width: 150px;
}
.faq-sec .faq-content {
    height: 364px;
}
.faq-sec .faq-content p {
    padding-top: 30px;
}
.platform .company-detail-box p {
    font-size: 16px;
}
.platform .platform_images h3 {
    font-size: 23px;
    margin: 0;
}
.services .service-box .ser-learn-more p {
    height: 86px;
}
.award_winning img {
    margin: 0 9px;
    width: 37px;
}
.our-process{
  display:none;
}
.testimonials h2 {
    margin: 2rem 0 1rem;
}
.testimonials ul li {
    margin: 0 6px;
}
.why_choose img {
    display: none;
}
.testimonials .review_box p {
    width: 88%;
}
.cta button {
    font-size: 16px;
    padding: 0px 30px;
    height: 48px;
}
.cta a {
    width: 47px;
    height: 47px;
}
.bar {
    background: linear-gradient(to right, rgb(187 23 47) 0, #e77c1d 100%);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bar a{
  color:#fff;
}
.header .offcanvas .learn-more, .learn-more:hover, .offcanvas-manu.nav ul li a, .service-box:hover, .text {
    font-size: 40px;
}
.header .offcanvas .accordion-button {
    font-size: 40px;
}
.award_winning h3 {
    font-size: 22px;
    margin: 0rem 0 1rem;
}
.services h2 {
    font-size: 21px;
    margin: 2rem 0 2rem;
        padding: 16px;

}
.services h2::after {
    width: 295px;
    height: 62px;
}
.testimonials .review_box .mx-5 img{
  display: none;
}

.banner-wrap .banner-btns button.popup-btn {
    font-size: 16px;
    width: 138px;
    padding: 6px 16px;
    margin: 0 10px 0 0;
}
.banner-wrap .banner-content .banner-brands img {
    width: 68px;
    height: 62px;
}
.banner-wrap .banner-form h3 {
    font-size: 18px;
    margin: 0 0 3px;
}
.cta .cta-bg {
    padding: 2rem 2rem;
}
.why_choose h2 {
    font-size: 33px;
}
.why_choose p {
    margin: 19px 0;
    font-size: 16px;
}
.faq-sec .head_txt h2 {
    font-size: 27px;
    margin: 0rem 0 1rem;
}
.faq-sec .head_txt p {
    margin: 22px 0;
    font-size: 17px;
}
.ft_form .form-bg, .tabs_content a {
    padding: 3rem 1rem;
}
.theme-btn {
    height: 32px;
    min-width: 32px;
    font-size: 18px;
    bottom: 99px;
}
footer .footer-bottom {
    font-size: 12px;
    text-align: center;
}
.floatbutton .btns_wrap .call_wrap span.icoo, .floatbutton .btns_wrap .chat_wrap span.icoo {
    padding: 6px 20px 5px;
    border: 2px solid #ded9d9;
}
  .inner-banner-sec {
    padding: 100px 0 50px 0;
  }

  .inner-banner-sec .banner-content h1 {
    font-size: 40px;
    line-height: 50px;
  }


  .about-sec-wrap {
    padding: 50px 0;
  }

  .about-sec-wrap .about-card {
    margin: 0 0 20px 0;
  }


  .Premium-ppc-sec {
    padding: 50px 0;
  }

  .Premium-ppc-sec .content h3 {
    font-size: 26px;
    line-height: 30px;
    margin: 0 0 10px 0;
  }

  .Premium-ppc-sec .ppc-card {
    min-height: auto;
    max-height: fit-content;
    margin: 0 0 30px 0;
  }

  .Premium-ppc-sec .ppc-card .main-img {
    width: 100px;
    height: 70px;
  }

  .Premium-ppc-sec .ppc-card .desc h4 {
    font-size: 22px;
    line-height: 25px;
  }

  .Premium-ppc-sec .ppc-card .desc p {
    font-size: 14px;
    line-height: 20px;
    min-height: auto;
    max-height: 120px;
    overflow-y: scroll;
  }

  .profund-sec-wrap {
    padding: 50px 0;
  }

  .profund-sec-wrap .content {
    margin: 0;
  }

  .profund-sec-wrap .profund-card {
    margin: 80px 0 0 0;
  }

  .profund-sec-wrap .profund-card .inner-box {
    min-height: auto;
    max-height: max-content;
  }

  .profund-sec-wrap .profund-card .inner-box .desc h4 {
    font-size: 22px;
    line-height: 25px;
  }

  .award-sec-wrap {
    padding: 50px 0;
  }

  .award-sec-wrap .award-box .title h2 {
    font-size: 26px;
    line-height: 30px;
  }

  .pricing-sec-wrap {
    padding: 50px 0;
  }
.contact-sec-wrap {
    padding: 0;
}
.modal-content {
    padding-left: 0;
    padding-bottom: 30px;
}
.modal-close-btn {
    top: 3%;
    right: 6%;
    background: #e4761e;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.header .offcanvas .offcanvas-manu.nav ul li {
    font-size: 2.4rem;
    padding-top: 10px;
}
.scroll-to-top {
    bottom: 66px;
}
.theme-btn.bt-support-now {
        bottom: 59px;
    left: 7px;
}
footer {
    padding: 7rem 0 8rem;
}
.mobile_menu {
display: block;
}
}

@media only screen and (min-width: 300px) and (max-width: 575px) {
  .faq-sec ,
.testimonials{
    overflow-x: hidden;
}
   .banner-wrap {
    padding: 6rem 1rem 0;
}
.banner-wrap .banner-form {
    width: 100%;
    margin: 2rem auto;
}
.header {
    padding: 7px 0;
    background: #000;
}
  .header header nav.navigation li {
      padding-right: 0;
  }
  .header header .navigation li a {
      font-size: 14px;
      margin: 0 8px;
  }   
  .header header .logo {
      width: 156px;
  }
  .header header .header-btn {
      font-size: 12px;
      padding: 9px 14px;
  }
  .banner-wrap .banner-content .banner-brands {
        padding: 0px 16px;
    width: 100%;
  }
  .banner-wrap .banner-content h1 {
          font-size: 41px;
    line-height: 53px;
  }
   .banner-wrap  .banner-content{
    /* text-align: center; */
  }
  .banner-wrap .banner-content p {
     font-size: 16px;
    width: 100%;
}
.banner-wrap .banner-content ul {
    margin: 30px 0;
    text-align: left;
}
.platform .platform_images .wrap img {
   width: 90px;
    margin: 0 16px;
    height: 26px;
}
.platform .platform_images .wrap{
  overflow: hidden;

}
.header header .navigation .dropdown-toggle {
    font-size: 14px;
}
.services .service-box {
    padding: 1rem;
    height: 316px;
  }

.services .service-box .ser-learn-more a {
    font-size: 14px;
    padding: 10px 14px;
}
.services .service-box h4 {
    font-size: 19px;
}
.tabs_portfolio ul li button {
       font-size: 12px;
    width: 112px;
    padding: 16px 0;
}
.tabs_portfolio ul li {
    margin: 9px 5px;
}
.tabs_portfolio .tab-content .portfolio a img {
    min-height: 331px;
    max-height: 331px;
}
.our-process {
    height: 1279px;
    background-size: 50%;
}
.cta h3 {
  font-size: 22px;
    margin: 1rem 0;
    line-height: 33px;
    text-align: center;
}
.cta p {
    text-align: center;
    margin: 21px 0;
    font-size: 16px;
}
.cta img {
    width: 93%;
    margin: 32px auto 0;
}
.testimonials p {
    margin: 1rem 0;
}
.ft_form h3 {
    font-size: 32px;
    line-height: 47px;
    margin: 0 0 10px 0;
}
.ft_form p, .overlay h4 {
    font-size: 16px;
}
footer .footer-logo {
    margin-bottom: 17px;
    width: 196px;
}
footer .footer-navigation h3, footer .learn-more-btn2, footer .packages-box-head span {
   font-size: 27px;
    margin: 15px 0;
    color: #FF9800;
}
footer .footer-navigation li a {
    font-size: 26px;
}
.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    width: 100%;
    margin: 0;
  }
.faq-sec .faq-content {
    height: 364px;
}
.faq-sec .faq-content p {
    padding-top: 30px;
}
.platform .company-detail-box p {
    font-size: 16px;
}
.platform .platform_images h3 {
    font-size: 23px;
    margin: 0;
}
.services .service-box .ser-learn-more p {
    height: 86px;
}
.award_winning img {
    margin: 0 9px;
    width: 37px;
}
.our-process{
  display:none;
}
.testimonials h2 {
    margin: 2rem 0 1rem;
}
.testimonials ul li {
    margin: 0 6px;
}
.why_choose img {
    display: none;
}
.testimonials .review_box p {
    width: 88%;
}
.cta button {
    font-size: 16px;
    padding: 0px 30px;
    height: 48px;
}
.cta a {
    width: 47px;
    height: 47px;
}
.bar {
    background: linear-gradient(to right, rgb(187 23 47) 0, #e77c1d 100%);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bar a{
  color:#fff;
}
.header .offcanvas .learn-more, .learn-more:hover, .offcanvas-manu.nav ul li a, .service-box:hover, .text {
    font-size: 26px;
}
.header .offcanvas .accordion-button {
    font-size: 26px;
}
ul.offcanvas-submenu {
    background: #000;
    padding: 2rem;
    border-radius: 10px;
}
ul.offcanvas-submenu li{
  padding: 0;
}
.header .offcanvas .close-btn-offcanvas {
    height: 40px;
}
.award_winning h3 {
    font-size: 22px;
    margin: 0rem 0 1rem;
}
.services h2 {
    font-size: 21px;
    margin: 2rem 0 2rem;
        padding: 16px;
}
.offcanvas-header img {
    width: 183px !IMPORTANT;
}
.services h2::after {
    width: 295px;
    height: 62px;
}
.testimonials .review_box .mx-5 img{
  display: none;
}

.banner-wrap .banner-btns button.popup-btn {
    font-size: 16px;
    width: 138px;
    padding: 6px 16px;
    margin: 0 10px 0 0;
}
.banner-wrap .banner-content .banner-brands img {
    width: 68px;
    height: 62px;
}
.banner-wrap .banner-form h3 {
    font-size: 18px;
    margin: 0 0 3px;
}
.cta .cta-bg {
    padding: 2rem 2rem;
}
.why_choose h2 {
    font-size: 33px;
}
.why_choose p {
    margin: 19px 0;
    font-size: 16px;
}
.faq-sec .head_txt h2 {
    font-size: 27px;
    margin: 0rem 0 1rem;
}
.faq-sec .head_txt p {
    margin: 22px 0;
    font-size: 17px;
}
.ft_form .form-bg, .tabs_content a {
    padding: 3rem 1rem;
}
.theme-btn {
        height: 52px;
    min-width: 16%;
    font-size: 24px;
    bottom: 117px;
    border-radius: 5px;
    left: 7px;
}
footer .footer-bottom {
    font-size: 12px;
    text-align: center;
}
.floatbutton .btns_wrap .call_wrap span.icoo, .floatbutton .btns_wrap .chat_wrap span.icoo {
    padding: 6px 20px 5px;
    border: 2px solid #ded9d9;
}

  .inner-banner-sec {
    padding: 100px 0 50px 0;
  }

  .inner-banner-sec .banner-content h1 {
    font-size: 34px;
    line-height: 40px;
    margin: 0;
  }

  .inner-banner-sec .banner-content p {
    font-size: 17px;
    line-height: 23px;
  }

  .inner-banner-sec .banner-content .brand-rating {
    margin: 40px 0 0 0;
  }

  .inner-banner-sec .banner-content .brand-rating .rating {
    width: 100%;
  }

  .inner-banner-sec .banner-content .brand-rating .brand-img {
    padding: 38px 20px 21px;
  }

  .inner-banner-sec .banner-content .brand-rating .brand-img img {
    min-width: 100%;
    max-width: 100%;
  }

  .inner-banner-sec .banner-content .banner-btn .btn-contact {
    font-size: 16px;
    padding: 0 16px;
        height: 38px;

  }

  .inner-banner-sec .main-img {
    margin: 40px 0 0 0;
  }

  .inner-banner-sec .main-img img {
    min-height: 320px;
    max-height: 320px;
  }

  .inner-banner-sec .banner-content .banner-btn {
    justify-content: center;
  }

  .about-sec-wrap {
    padding: 50px 0;
  }

  .about-sec-wrap .about-card {
    margin: 0 0 20px 0;
  }

  .Premium-ppc-sec {
    padding: 50px 0;
  }

  .Premium-ppc-sec .content h3 {
    font-size: 26px;
    line-height: 30px;
    margin: 0 0 10px 0;
  }

  .Premium-ppc-sec .ppc-card {
    min-height: auto;
    max-height: max-content;
    margin: 0 0 30px 0;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .Premium-ppc-sec .ppc-card .main-img {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }

  .Premium-ppc-sec .ppc-card .desc h4 {
    font-size: 22px;
    line-height: 25px;
  }

  .Premium-ppc-sec .ppc-card .desc p {
    font-size: 14px;
    line-height: 20px;
  }

  .profund-sec-wrap {
    padding: 50px 0;
  }

  .profund-sec-wrap .content {
    margin: 0;
  }

  .profund-sec-wrap .content h2 {
    font-size: 24px;
  }

  .profund-sec-wrap .profund-card {
    margin: 80px 0 0 0;
  }

  .profund-sec-wrap .profund-card .inner-box {
    padding: 45px 5px;
    min-height: auto;
    max-height: max-content;
  }

  .profund-sec-wrap .profund-card .inner-box .desc h4 {
    font-size: 22px;
    line-height: 25px;
  }

  .profund-sec-wrap .profund-card .inner-box .desc p {
    font-size: 16px;
    line-height: 21px;
  }

  .award-sec-wrap {
    padding: 50px 0;
  }

  .award-sec-wrap .award-box .title h2 {
    font-size: 26px;
    line-height: 30px;
  }

  .pricing-sec-wrap {
    padding: 50px 0;
  }

  .pricing-sec-wrap .pricing-card .title h2 {
    font-size: 24px;
    line-height: 30px;
    width: 100%;
  }

  .pricing-sec-wrap .pricing-card .inner-box h4 {
    font-size: 45px;
    line-height: 65px;
  }

  .pricing-sec-wrap .pricing-card .inner-box ul li {
    font-size: 16px;
    line-height: 21px;
  }

  .pricing-sec-wrap .pricing-card .inner-box .pricing-btns .btn-pricing {
    padding: 0 10px;
    font-size: 16px;
    line-height: 16px;
  }
.contact-sec-wrap {
    padding: 0;
}
.information {
    margin: 19px 0;
}
.vision-sec-warp {
    padding: 38px 0;
}
.vision-sec-warp .desc h2 {
    margin: 20px 0;

  }  
.inner-banner-sec.big-banner {
    min-height: 489px;
    max-height: 453px;
}
.modal-heading {
    font-size: 2rem;
}
.discount {
    padding-left: 0;
    font-size: 26px;
    margin: 0;
}
.modal-content {
    padding-left: 0;
    padding-bottom: 30px;
}
.modal-close-btn {
    top: 3%;
    right: 6%;
    background: #e4761e;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.header .offcanvas .offcanvas-manu.nav ul li {
    font-size: 2.4rem;
    padding-top: 10px;
}
.scroll-to-top {
    bottom: 66px;
}
.theme-btn.bt-support-now {
        bottom: 59px;
    left: 7px;
}
footer {
    padding: 7rem 0 8rem;
}
.mobile_menu {
display: block;
}
}

